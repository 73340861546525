@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

/* Scrollbar styling */
::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #111827;
  }
  
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom, #2dd4bf, #3b82f6);
    border-radius: 5px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to bottom, #14b8a6, #2563eb);
  }
  
  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #2dd4bf #111827;
  }